@font-face {
  font-family: "BODDAT";
  src: url("./fonts/TTF.ttf") format(".ttf");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "BODDAT", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "BODDAT", "Courier New", monospace;
}
